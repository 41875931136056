import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  stock: [],
  stockAt: [],
};

const stock = createSlice({
  name: "stock",
  initialState,
  reducers: {
    setStockEvolution(state, action) {
      state.stock = action.payload.stock;
    },
    setStockAt(state, action) {
      state.stockAt = action.payload.stockAt;
    },
  },
});

export default stock.reducer;

export const { setStockEvolution, setStockAt } = stock.actions;
