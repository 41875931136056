// assets
import { UserOutlined } from "@ant-design/icons";

// ==============================|| MENU ITEMS - DASHBOARD ||============================== //

const relations = {
  id: "group-relations",
  title: "Relations",
  type: "group",
  children: [
    {
      id: "customers",
      title: "Clients",
      type: "item",
      url: "/dashboard/customers",
      icon: UserOutlined,
      breadcrumbs: true,
    },
    {
      id: "provider",
      title: "Fournisseurs",
      type: "item",
      url: "/dashboard/suppliers",
      icon: UserOutlined,
      breadcrumbs: true,
    },
  ],
};

export default relations;
