import { createSlice } from "@reduxjs/toolkit";

const user = JSON.parse(localStorage.getItem("user"));

const initialState = user
  ? { isLoggedIn: true, user }
  : { isLoggedIn: false, user: null };

const auth = createSlice({
  name: "auth",
  initialState,
  reducers: {
    logoutUser(state, _) {
      state.user = null;
      state.isLoggedIn = false;
    },

    loginUser(state, action) {
      state.user = action.payload.user;
      state.isLoggedIn = true;
    },
  },
});

export default auth.reducer;

export const { logoutUser, loginUser } = auth.actions;
