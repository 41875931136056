import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  proformas: [],
  proformasCount: 0,
  areProformasLoaded: false,
  selectedProformasIds: [],
};

const proforma = createSlice({
  name: "proforma",
  initialState,
  reducers: {
    addProforma(state, action) {
      state.proformas = [
        ...state.proformas,
        action.payload.proforma,
      ];
    },
    setProformas(state, action) {
      state.proformas = action.payload.proforma.proformas.items;
      state.proformasCount = action.payload.proforma.proformas.count;
      state.areProformasLoaded = true;
    },
    updateProforma(state, action) {
      const { proformas } = state;
      const index = proformas.findIndex((proforma) => proforma.id === action.payload.proforma.id);
      if (index !== -1) {
        proformas.splice(index, 1, action.payload.proforma);
      }
      state.proformas = proformas;
    },
    deleteProforma(state, action) {
      state.proformas = state.proformas.filter((proforma) => !action.payload.proformaIds.includes(proforma.id));
    },
  },
});

export default proforma.reducer;

export const {
  addProforma, setProformas, updateProforma, deleteProforma,
} = proforma.actions;
