import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  products: [],
  areProductsLoaded: false,
  productsCount: 0,
  selectedProductsIds: [],
};

const product = createSlice({
  name: "product",
  initialState,
  reducers: {
    addProduct(state, action) {
      state.products = [
        ...state.products,
        action.payload.product,
      ];
    },
    setProducts(state, action) {
      state.products = action.payload.products.items;
      state.productsCount = action.payload.products.count;
      state.areProductsLoaded = true;
    },
    updateProduct(state, action) {
      const { products } = state;
      const index = products.findIndex((product) => product.id === action.payload.product.id);
      if (index !== -1) {
        products.splice(index, 1, action.payload.product);
      }
      state.products = products;
    },
    deleteProduct(state, action) {
      state.products = state.products.filter((product) => !action.payload.productIds.includes(product.id));
    },
  },
});

export default product.reducer;

export const {
  addProduct, setProducts, updateProduct, deleteProduct,
} = product.actions;
