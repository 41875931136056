import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  company: {},
};

const company = createSlice({
  name: "company",
  initialState,
  reducers: {
    setCompany(state, action) {
      state.company = action.payload.company;
    },
  },
});

export default company.reducer;

export const { setCompany } = company.actions;
