import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  expenses: [],
  expensesCount: 0,
  areExpensesLoaded: false,
  selectedExpensesIds: [],
};

const expense = createSlice({
  name: "expense",
  initialState,
  reducers: {
    addExpense(state, action) {
      state.expenses = [
        ...state.expenses,
        action.payload.expense,
      ];
    },
    setExpenses(state, action) {
      state.expenses = action.payload.expenses.items;
      state.expensesCount = action.payload.expenses.count;
      state.areExpensesLoaded = true;
    },
    updateExpense(state, action) {
      const { expenses } = state;
      const index = expenses.findIndex((expense) => expense.id === action.payload.expense.id);
      if (index !== -1) {
        expenses.splice(index, 1, action.payload.expense);
      }
      state.expenses = expenses;
    },
    deleteExpense(state, action) {
      state.expenses = state.expenses.filter((expense) => !action.payload.expenseIds.includes(expense.id));
    },
  },
});

export default expense.reducer;

export const {
  addExpense, setExpenses, updateExpense, deleteExpense,
} = expense.actions;
