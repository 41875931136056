import {
  getCompany as get,
  updateCompany as update,
  uploadLogo as sendLogo,
} from "services/company.service";
import { setCompany } from "store/reducers/company";

export const getCompany = () => (dispatch) => {
  const response = get();
  dispatch(setCompany({ company: response.company }));
};

export const updateCompany = (companyId, companyData) => async (dispatch) => {
  const response = await update(companyId, companyData);
  dispatch(setCompany({ company: response.data.company }));
};

export const uploadLogo = (companyId, logo) => async (dispatch) => {
  await sendLogo(companyId, logo);
};
