import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  suppliers: [],
  suppliersCount: 0,
  areSuppliersLoaded: false,
  selectedSuppliersIds: [],
};

const supplier = createSlice({
  name: "supplier",
  initialState,
  reducers: {
    addSupplier(state, action) {
      state.suppliers = [
        ...state.suppliers,
        action.payload.supplier,
      ];
    },
    setSuppliers(state, action) {
      state.suppliers = action.payload.suppliers.items;
      state.suppliersCount = action.payload.suppliers.count;
      state.areSuppliersLoaded = true;
    },
    updateSupplier(state, action) {
      const { suppliers } = state;
      const index = suppliers.findIndex((supplier) => supplier.id === action.payload.supplier.id);
      if (index !== -1) {
        suppliers.splice(index, 1, action.payload.supplier);
      }
      state.suppliers = suppliers;
    },
    deleteSupplier(state, action) {
      state.suppliers = state.suppliers.filter((supplier) => !action.payload.supplierIds.includes(supplier.id));
    },
  },
});

export default supplier.reducer;

export const {
  addSupplier, setSuppliers, updateSupplier, deleteSupplier,
} = supplier.actions;
