import { lazy } from "react";
// import Supplier from 'reducers/supplier';

// project import
import Loadable from "../components/Loadable";
import MainLayout from "../layout/MainLayout";

// // render - dashboard
const DashboardDefault = Loadable(lazy(() => import("pages/dashboard")));
const Product = Loadable(lazy(() => import("pages/products")));
const Customer = Loadable(lazy(() => import("pages/customers")));
const CustomersDetails = Loadable(lazy(() => import("components/customers-details")));

const Account = Loadable(lazy(() => import("pages/account")));
const Expense = Loadable(lazy(() => import("pages/expenses")));
const Proforma = Loadable(lazy(() => import("pages/proformas")));
const Purchase = Loadable(lazy(() => import("pages/purchases")));
const PurchasesDetails = Loadable(lazy(() => import("components/purchases-details")));

const Sale = Loadable(lazy(() => import("pages/sales")));
const Stock = Loadable(lazy(() => import("pages/stock")));
const Supplier = Loadable(lazy(() => import("pages/suppliers")));
const SuppliersDetails = Loadable(lazy(() => import("components/suppliers-details")));

const Invoice = Loadable(lazy(() => import("pages/invoice")));

// ==============================|| MAIN ROUTING ||============================== //

const MainRoutes = {
  path: "/dashboard",
  element: <MainLayout />,
  children: [
    {
      path: "/dashboard",
      element: <DashboardDefault />,
    },
    {
      path: "/dashboard/products",
      element: <Product />,
    },
    {
      path: "/dashboard/customers",
      element: <Customer />,
    },
    {
      path: "/dashboard/customers/:id",
      element: <CustomersDetails />,
    },
    {
      path: "/dashboard/account",
      element: <Account />,
    },
    {
      path: "/dashboard/expenses",
      element: <Expense />,
    },
    {
      path: "/dashboard/proformas",
      element: <Proforma />,
    },
    {
      path: "/dashboard/proformas/:id",
      element: <Invoice />,
    },
    {
      path: "/dashboard/purchases",
      element: <Purchase />,
    },
    {
      path: "/dashboard/purchases/:id",
      element: <PurchasesDetails />,
    },
    {
      path: "/dashboard/sales",
      element: <Sale />,
    },
    {
      path: "/dashboard/sales/:id",
      element: <Invoice />,
    },
    {
      path: "/dashboard/stock",
      element: <Stock />,
    },
    {
      path: "/dashboard/suppliers",
      element: <Supplier />,
    },
    {
      path: "/dashboard/suppliers/:id",
      element: <SuppliersDetails />,
    },
  ],
};

export default MainRoutes;
