// assets
import { DashboardOutlined } from "@ant-design/icons";

// icons
const icons = {
  DashboardOutlined,
};

// ==============================|| MENU ITEMS - DASHBOARD ||============================== //

const account = {
  id: "group-account",
  title: "Mon compte",
  type: "group",
  children: [
    {
      id: "account",
      title: "Mes informations",
      type: "item",
      url: "/dashboard/account",
      icon: icons.DashboardOutlined,
      breadcrumbs: true,
    },
  ],
};

export default account;
