// third-party
import { combineReducers } from "redux";

// project import
import menu from "./menu";
import auth from "./auth";
import notification from "./notification";
import product from "./product";
import company from "./company";
import customer from "./customer";
import expense from "./expense";
import proforma from "./proforma";
import purchase from "./purchase";
import sale from "./sale";
import stock from "./stock";
import supplier from "./supplier";

// ==============================|| COMBINE REDUCERS ||============================== //

const reducers = combineReducers({
  menu,
  auth,
  notification,
  product,
  company,
  customer,
  expense,
  proforma,
  purchase,
  sale,
  stock,
  supplier,
});

export default reducers;
