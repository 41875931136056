import ReactDOM from "react-dom/client";
import "./assets/css/index.scss";

import Entry from "./App";

import * as serviceWorkerRegistration from "./serviceWorkerRegistration";
import reportWebVitals from "./reportWebVitals";

// window.Buffer = Buffer;

// // Opt-in to Webpack hot module replacement
// if (module.hot) module.hot.accept();

const app = ReactDOM.createRoot(document.getElementById("app"));

app.render(Entry);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://cra.link/PWA
serviceWorkerRegistration.register();

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
