import { showErrorNotification, showSuccessNotification, resetNotification } from "store/reducers/notification";

export const closeNotification = () => async (dispatch) => {
  dispatch(resetNotification());
};

export const showSuccessMessage = (messageText) => async (dispatch) => {
  dispatch(showSuccessNotification({ message: messageText }));
};

export const showErrorMessage = (messageText) => async (dispatch) => {
  dispatch(showErrorNotification({ message: messageText }));
};
