import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  notification: {
    isShow: false,
    message: "",
    type: "",
  },
};

const notification = createSlice({
  name: "notification",
  initialState,
  reducers: {
    resetNotification(state, action) {
      state.notification.isShow = false;
    },

    showErrorNotification(state, action) {
      state.notification.message = action.payload.message;
      state.notification.type = "error";
      state.notification.isShow = true;
    },

    showSuccessNotification(state, action) {
      state.notification.message = action.payload.message;
      state.notification.type = "success";
      state.notification.isShow = true;
    },
  },
});

export default notification.reducer;

export const { showErrorNotification, showSuccessNotification, resetNotification } = notification.actions;
