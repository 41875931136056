// project import
import dashboard from "./dashboard";
import operations from "./operations";
import relations from "./relations";
import account from "./account";
import products from "./products";
// ==============================|| MENU ITEMS ||============================== //

const menuItems = {
  items: [dashboard, products, relations, operations, account],
};

export default menuItems;
