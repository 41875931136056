// assets
import { DollarOutlined, DashboardOutlined } from "@ant-design/icons";

// ==============================|| MENU ITEMS - DASHBOARD ||============================== //

const operations = {
  id: "group-operations",
  title: "Opérations",
  type: "group",
  children: [
    {
      id: "sales",
      title: "Ventes",
      type: "item",
      url: "/dashboard/sales",
      icon: DollarOutlined,
      breadcrumbs: true,
    },
    {
      id: "purchases",
      title: "Achats",
      type: "item",
      url: "/dashboard/purchases",
      icon: DashboardOutlined,
      breadcrumbs: true,
    },
    {
      id: "proforma",
      title: "Proforma",
      type: "item",
      url: "/dashboard/proformas",
      icon: DashboardOutlined,
      breadcrumbs: true,
    },
    {
      id: "expenses",
      title: "Dépenses",
      type: "item",
      url: "/dashboard/expenses",
      icon: DashboardOutlined,
      breadcrumbs: true,
    },
  ],
};

export default operations;
