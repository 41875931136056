import {
  register as signup, logout as disonnect, login as signin, registerByInvitation as registerFromInvitation,
} from "services/auth.service";
import { logoutUser, loginUser } from "store/reducers/auth";
import { showErrorNotification, showSuccessNotification } from "store/reducers/notification";

export const register = (
  lastName,
  firstName,
  company,
  phoneNumber,
  address,
  email,
  password,
) => (dispatch) => signup(lastName, firstName, company, phoneNumber, address, email, password).then(
  (response) => {
    dispatch(logoutUser());

    dispatch(showSuccessNotification({ message: response.data.message }));

    return Promise.resolve();
  },
  (error) => {
    const message = (error.response
      && error.response.data
      && error.response.data.message)
      || error.message
      || error.toString();

    dispatch(logoutUser());

    dispatch(showErrorNotification({ message }));

    return Promise.reject();
  },
);

export const login = (email, password) => (dispatch) => signin(email, password).then(
  (data) => {
    dispatch(loginUser({ user: data }));

    return Promise.resolve();
  },
  (error) => {
    const message = (error.response
      && error.response.data
      && error.response.data.message)
      || error.message
      || error.toString();

    dispatch(showErrorNotification({ message }));
    return Promise.reject();
  },
);

export const logout = () => (dispatch) => {
  disonnect();
  dispatch(logoutUser());
};

export const registerByInvitation = (
  lastName,
  firstName,
  phoneNumber,
  email,
  password,
  companyId,
) => (dispatch) => registerFromInvitation(lastName, firstName, phoneNumber, email, password, companyId).then(
  (response) => {
    dispatch(showSuccessNotification({ message: response.data.message }));
    return Promise.resolve();
  },
  (error) => {
    const message = (error.response
      && error.response.data
      && error.response.data.message)
      || error.message
      || error.toString();

    dispatch(logoutUser());

    dispatch(showErrorNotification({ message }));

    return Promise.reject();
  },
);
