import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  sales: [],
  salesCount: 0,
  areSalesLoaded: false,
  selectedSalesIds: [],
};

const sale = createSlice({
  name: "sale",
  initialState,
  reducers: {
    addSale(state, action) {
      state.sales = [
        ...state.sales,
        action.payload.sale,
      ];
    },
    setSales(state, action) {
      state.sales = action.payload.sales.items;
      state.salesCount = action.payload.sales.count;
      state.areSalesLoaded = true;
    },
    updateSale(state, action) {
      const { sales } = state;
      const index = sales.findIndex((sale) => sale.id === action.payload.sale.id);
      if (index !== -1) {
        sales.splice(index, 1, action.payload.sale);
      }
      state.sales = sales;
    },
    deleteSale(state, action) {
      state.sales = state.sales.filter((sale) => !action.payload.saleIds.includes(sale.id));
    },
  },
});

export default sale.reducer;

export const {
  addSale, setSales, updateSale, deleteSale,
} = sale.actions;
