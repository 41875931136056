import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  customers: [],
  customersCount: 0,
  areCustomersLoaded: false,
  selectedCustomersIds: [],
};

const customer = createSlice({
  name: "customer",
  initialState,
  reducers: {
    addCustomer(state, action) {
      state.customers = [
        ...state.customers,
        action.payload.customer,
      ];
    },
    setCustomers(state, action) {
      state.customers = action.payload.customers.items;
      state.customersCount = action.payload.customers.count;
      state.areCustomersLoaded = true;
    },
    updateCustomer(state, action) {
      const { customers } = state;
      const index = customers.findIndex((customer) => customer.id === action.payload.customer.id);
      if (index !== -1) {
        customers.splice(index, 1, action.payload.customer);
      }
      state.customers = customers;
    },
    deleteCustomers(state, action) {
      state.customers = state.customers.filter((customer) => !action.payload.customerIds.includes(customer.id));
    },
  },
});

export default customer.reducer;

export const {
  addCustomer, setCustomers, updateCustomer, deleteCustomers,
} = customer.actions;
