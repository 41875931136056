import api from "../axios/index";

const updateCompany = (companyId, {
  name,
  address,
  logo,
  currency,
}) => {
  let user = JSON.parse(localStorage.getItem("user"));

  user = {
    ...user,
    company: {
      ...user.company,
      name,
      address,
      logo,
      currency,
    },
  };

  localStorage.setItem("user", JSON.stringify(user));

  return api.put(`/api/company/update?id=${companyId}`, {
    name,
    address,
    logo,
    currency,
  });
};

const getCompany = () => {
  const user = JSON.parse(localStorage.getItem("user"));
  if (!user) {
    return {};
  }
  const { company } = user;
  return { company };
};

const uploadLogo = (companyId, logo) => api.post(`/api/company/upload/logo?id=${companyId}`, logo, { headers: { "Content-Type": "multipart/form-data" } });

const getLogo = (companyId) => api.get(`/api/company/logo?id=${companyId}`);

export {
  uploadLogo,
  updateCompany,
  getLogo,
  getCompany,
};
