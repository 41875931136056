import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  purchases: [],
  purchasesCount: 0,
  arePurchasesLoaded: false,
  selectedPurchasesIds: [],
};

const purchase = createSlice({
  name: "purchase",
  initialState,
  reducers: {
    addPurchase(state, action) {
      state.purchases = [
        ...state.purchases,
        action.payload.purchase,
      ];
    },
    setPurchases(state, action) {
      state.purchases = action.payload.purchases.items;
      state.purchasesCount = action.payload.purchases.count;
      state.arePurchasesLoaded = true;
    },
    updatePurchase(state, action) {
      const { purchases } = state;
      const index = purchases.findIndex((purchase) => purchase.id === action.payload.purchase.id);
      if (index !== -1) {
        purchases.splice(index, 1, action.payload.purchase);
      }
      state.purchases = purchases;
    },
    deletePurchase(state, action) {
      state.purchases = state.purchases.filter((purchase) => !action.payload.purchaseIds.includes(purchase.id));
    },
  },
});

export default purchase.reducer;

export const {
  addPurchase, setPurchases, updatePurchase, deletePurchase,
} = purchase.actions;
