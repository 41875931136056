// assets
import { LoginOutlined, ProfileOutlined } from "@ant-design/icons";

// icons
const icons = {
  LoginOutlined,
  ProfileOutlined,
};

// ==============================|| MENU ITEMS - EXTRA PAGES ||============================== //

const products = {
  id: "authentication",
  title: "Articles",
  type: "group",
  children: [
    {
      id: "products",
      title: "Mes produits",
      type: "item",
      url: "/dashboard/products",
      icon: icons.LoginOutlined,
      breadcrumbs: false,

      // target: true
    },
    {
      id: "stock",
      title: "Mon stock",
      type: "item",
      url: "/dashboard/stock",
      icon: icons.ProfileOutlined,
      // target: true
    },
  ],
};

export default products;
