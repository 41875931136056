import api from "../axios/index";

export const register = (
  lastName,
  firstName,
  company,
  phoneNumber,
  address,
  email,
  password,
) => api.post("/api/auth/signup", {
  lastName,
  firstName,
  company,
  phoneNumber,
  address,
  email,
  password,
});

export const registerByInvitation = (
  lastName,
  firstName,
  phoneNumber,
  email,
  password,
  companyId,
) => api.post("/api/auth/signup-by-invitation", {
  lastName,
  firstName,
  phoneNumber,
  email,
  password,
  companyId,
});

export const login = (email, password) => api
  .post("/api/auth/signin", {
    email,
    password,
  })
  .then((response) => {
    if (response.data.accessToken) {
      localStorage.setItem("user", JSON.stringify(response.data));
    }

    return response.data;
  });

export const logout = () => {
  localStorage.removeItem("user");
};

export const forgotPassword = async (email) => {
  await api.post("/api/auth/forgot-password", { email });
};

export const resetPassword = async (token, password) => {
  await api.post(`/api/auth/reset-password?${token}`, { password });
};
