// material-ui
import { useTheme } from "@mui/material/styles";

/**
 * if you want to use image instead of <svg> uncomment following.
 *
 * import logoDark from 'assets/images/logo-dark.svg';
 * import logo from 'assets/images/logo.svg';
 *
 */

// ==============================|| LOGO SVG ||============================== //

function Logo() {
  const theme = useTheme();

  return (
    /**
     * if you want to use image instead of svg uncomment following, and comment out <svg> element.
     *
     * <img src={logo} alt="Mantis" width="100" />
     *
     */
    <svg
      version="1.0"
      xmlns="http://www.w3.org/2000/svg"
      width="24.000000pt"
      height="24.000000pt"
      viewBox="0 0 16.000000 16.000000"
      preserveAspectRatio="xMidYMid meet"
    >

      <g
        transform="translate(0.000000,16.000000) scale(0.100000,-0.100000)"
        fill="#000000"
        stroke="none"
      >
        <path
          d="M4 147 c-3 -8 -4 -43 -2 -78 l3 -64 75 0 75 0 0 75 0 75 -73 3 c-55 2 -74 -1 -78 -11z m113 -58 c-4 -32 -2 -37 12 -32 15 5 15 4 -1 -12 -9 -10 -23 -15 -32 -12 -12 5 -13 13 -6 38 5 18 8 35 5 37 -9 10 -25 -11 -31 -43 -10 -54 -27 -55 -23 -1 3 35 1 46 -10 46 -26 0 2 18 34 22 50 6 58 -1 52 -43z"
          fill={theme.palette.primary.dark}
        />
      </g>
    </svg>
  );
}

export default Logo;
