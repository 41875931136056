import Routes from "routes";
import ThemeCustomization from "themes";
import ScrollTop from "components/ScrollTop";
import { BrowserRouter } from "react-router-dom";
import NotificationComponent from "components/shared/NotificationComponent";

// ==============================|| APP - THEME, ROUTER, LOCAL  ||============================== //

function App() {
  return (
    <BrowserRouter>
      <ThemeCustomization>
        <NotificationComponent />
        <ScrollTop>
          <Routes />
        </ScrollTop>
      </ThemeCustomization>
    </BrowserRouter>
  );
}

export default App;
