import * as React from "react";
import Snackbar from "@mui/material/Snackbar";
import MuiAlert from "@mui/material/Alert";
import { useDispatch, useSelector } from "react-redux";
import { closeNotification } from "actions/notification";

const Alert = React.forwardRef((props, ref) => <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />);

const NotificationComponent = function NotificationComponent(props) {
  const { notification } = useSelector((state) => state.notification);

  const dispatch = useDispatch();

  const handleClose = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }

    dispatch(closeNotification());
  };

  return (
    <Snackbar
      anchorOrigin={{ vertical: "top", horizontal: "center" }}
      open={notification?.isShow}
      autoHideDuration={6000}
      onClose={handleClose}
    >
      <Alert onClose={handleClose} severity={notification?.type} sx={{ width: "100%" }}>
        {notification?.message}
      </Alert>
    </Snackbar>
  );
};

NotificationComponent.defaultProps = {
  type: "success",
  message: "message",
};

export default NotificationComponent;
